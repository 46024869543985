import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GameData, DataExplorerGameData } from '../models/common'

export const mlbGameApi = createApi({
  reducerPath: 'mlbGameApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://wager-trends-mlb.azurewebsites.net/api/mlb' : process.env.PUBLIC_URL + '/api/mlb'
  }),
  endpoints: (builder) => ({

    getMLBGamesOnly: builder.query<GameData[], undefined>({
      query: () => 'games'
    }),

    getAllMLBGames: builder.query<GameData[], undefined>({
      query: () => 'games/?includeBetData=true'
    }),

    getMLBGameById: builder.query<GameData, string>({
      query: (gameId) => `games/?includeBetData=true&gameId=${gameId}`
    }),

    getMLBGamesByTeamId: builder.query<GameData[], string>({
      query: (teamId) => `games/?includeBetData=true&teamId=${teamId}`
    }),

    getMLBRawGameData: builder.query<DataExplorerGameData, string>({
      query: (gameId) => `games/?includeBetData=true&gameId=${gameId}&raw=true`
    })

    // TODO: Make a query that gets by parameter so we don't load all team data every time
  })
})

export const {
  useGetMLBGamesOnlyQuery,
  useGetAllMLBGamesQuery,
  useGetMLBGameByIdQuery,
  useGetMLBGamesByTeamIdQuery,
  useGetMLBRawGameDataQuery
} = mlbGameApi 