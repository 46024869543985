import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { TeamData } from '../models'

export const nbaTeamApi = createApi({
  reducerPath: 'nbaTeamApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.PUBLIC_URL + '/teams'
  }),
  endpoints: (builder) => ({

    // Team Queries
    getAllNBATeams: builder.query<TeamData[], undefined>({
      query: () => './nba.json'
    }),

    getNBATeamById: builder.query<TeamData, string>({
      query: (teamId) => `teams/${teamId}`
    })
  })
})

export const { useGetAllNBATeamsQuery, useGetNBATeamByIdQuery } = nbaTeamApi