import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { TeamData } from '../models/'

export const mlbTeamApi = createApi({
  reducerPath: 'mlbTeamApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.PUBLIC_URL + '/teams'
  }),
  endpoints: (builder) => ({

    // Team Queries
    getAllMLBTeams: builder.query<TeamData[], void>({
      query: () => './mlb.json'
    }),

    getMLBTeamById: builder.query<TeamData, string>({
      query: (teamId) => `teams/${teamId}`
    })
  })
})

export const { useGetAllMLBTeamsQuery, useGetMLBTeamByIdQuery } = mlbTeamApi