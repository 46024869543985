import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GameData, DataExplorerGameData } from '../models/common'

export const nbaGameApi = createApi({
  reducerPath: 'nbaGameApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://wager-trends-nba.azurewebsites.net/api/nba' : process.env.PUBLIC_URL + '/api/nba'
  }),
  endpoints: (builder) => ({

    getNBAGamesOnly: builder.query<GameData[], undefined>({
      query: () => 'games'
    }),

    getAllNBAGames: builder.query<GameData[], undefined>({
      query: () => 'games/?includeBetData=true'
    }),

    getNBAGameById: builder.query<GameData, string>({
      query: (gameId) => `games/?includeBetData=true&gameId=${gameId}`
    }),

    getNBAGamesByTeamId: builder.query<GameData[], string>({
      query: (teamId) => `games/?includeBetData=true&teamId=${teamId}`
    }),

    getNBARawGameData: builder.query<DataExplorerGameData, string>({
      query: (gameId) => `games/?includeBetData=true&gameId=${gameId}&raw=true`
    })

    // TODO: Make a query that gets by parameter so we don't load all team data every time
  })
})

export const {
  useGetNBAGamesOnlyQuery,
  useGetAllNBAGamesQuery,
  useGetNBAGameByIdQuery,
  useGetNBAGamesByTeamIdQuery,
  useGetNBARawGameDataQuery
} = nbaGameApi 