import { Link } from "react-router-dom"
import { BaseballIcon, BasketballIcon, FootballIcon, HockeyIcon } from "../Icon"
import { HomeIcon } from "../Icon/MiscIcon"

import './NavLinkIcon.scss'

export interface NavLinkIconProps {
  path: string
  type: 'home' | 'mlb' | 'nhl' | 'nba' | 'nfl'
  disableMargin?: boolean
  selected?: boolean
}

export const NavLinkIcon = (props: NavLinkIconProps) => {
  const { path, type, disableMargin, selected } = props

  return (
    <Link to={path}>
      <div 
        className="icon-nav-link-container"
        style={{
          marginBottom: disableMargin ? 0 : undefined,
          marginTop: disableMargin ? 0 : undefined
        }}
      >
        <div className="logo-container">
          {(() => {
            switch(type) {
              case 'home':
                return (<HomeIcon />)
              case 'mlb':
                return (<BaseballIcon />)
              case 'nhl':
                return (<HockeyIcon />)
              case 'nba':
                return (<BasketballIcon />)
              case 'nfl':
                return (<FootballIcon />)
            }
          })()}
        </div>
        <div 
          className={`text-container ${selected ? 'selected' : ''}`}
        >
          {(() => {
            switch(type) {
              case 'home':
                return ('Home')
              case 'mlb':
                return ('MLB')
              case 'nhl':
                return('NHL')
              case 'nba':
                return ('NBA')
              case 'nfl':
                return ('NFL')
            }
          })()}
        </div>
      </div>
    </Link>
  )
}