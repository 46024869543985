import { configureStore } from '@reduxjs/toolkit'

import { 
  mlbGameApi, 
  mlbTeamApi, 
  nhlGameApi, 
  nhlTeamApi, 
  nbaGameApi, 
  nbaTeamApi,
  gameApi 
} from './services'
import teamsReducer from './redux/teamsSlice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

export const store = configureStore({
  reducer: {
    [mlbGameApi.reducerPath]: mlbGameApi.reducer,
    [mlbTeamApi.reducerPath]: mlbTeamApi.reducer,
    [nhlGameApi.reducerPath]: nhlGameApi.reducer,
    [nhlTeamApi.reducerPath]: nhlTeamApi.reducer,
    [nbaGameApi.reducerPath]: nbaGameApi.reducer,
    [nbaTeamApi.reducerPath]: nbaTeamApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
    teams: teamsReducer
  },
  middleware: (geDefaultMiddleware) => (
    geDefaultMiddleware()
      .concat(mlbGameApi.middleware)
      .concat(mlbTeamApi.middleware)
      .concat(nhlGameApi.middleware)
      .concat(nhlTeamApi.middleware)
      .concat(nbaGameApi.middleware)
      .concat(nbaTeamApi.middleware)
      .concat(gameApi.middleware)
  )
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch