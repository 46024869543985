import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GameData, DataExplorerGameData } from '../models/common'

export const nhlGameApi = createApi({
  reducerPath: 'nhlGameApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://wager-trends-nhl.azurewebsites.net/api/nhl' : process.env.PUBLIC_URL + '/api/nhl'
  }),
  endpoints: (builder) => ({

    getNHLGamesOnly: builder.query<GameData[], undefined>({
      query: () => 'games'
    }),

    getAllNHLGames: builder.query<GameData[], undefined>({
      query: () => 'games/?includeBetData=true'
    }),

    getNHLGameById: builder.query<GameData, string>({
      query: (gameId) => `games/?includeBetData=true&gameId=${gameId}`
    }),

    getNHLGamesByTeamId: builder.query<GameData[], string>({
      query: (teamId) => `games/?includeBetData=true&teamId=${teamId}`
    }),

    getNHLRawGameData: builder.query<DataExplorerGameData, string>({
      query: (gameId) => `games/?includeBetData=true&gameId=${gameId}&raw=true`
    })

    // TODO: Make a query that gets by parameter so we don't load all team data every time
  })
})

export const {
  useGetNHLGamesOnlyQuery,
  useGetAllNHLGamesQuery,
  useGetNHLGameByIdQuery,
  useGetNHLGamesByTeamIdQuery,
  useGetNHLRawGameDataQuery
} = nhlGameApi 