import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GameData, DataExplorerGameData } from '../models/common'

export const gameApi = createApi({
  reducerPath: 'gameApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'https://wager-trends-sql.azurewebsites.net/api'
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://wager-trends-http.azurewebsites.net/api' : process.env.PUBLIC_URL + '/api'
  }),
  endpoints: (builder) => ({

    getGamesOnly: builder.query<GameData[], string>({
      query: (sportKey) => `games?sportKey=${sportKey}`
    }),

    getAllGames: builder.query<GameData[], {marketKey: string, sportKey: string, includePrices: boolean}>({
      query: ({marketKey, sportKey, includePrices}) => `games?includeBetData=true&includePrices=${includePrices}&sportKey=${sportKey}&marketKey=${marketKey}`,
      serializeQueryArgs: (args) => `${args.queryArgs.marketKey}-${args.queryArgs.sportKey}-${args.queryArgs.includePrices}`
    }),

    getGameById: builder.query<GameData, {gameId: string, marketKey: string, sportKey: string, includePrices: boolean}>({
      query: ({gameId, marketKey, sportKey, includePrices}) => `games?includeBetData=true&includePrices=${includePrices}&sportKey=${sportKey}&gameId=${gameId}&marketKey=${marketKey}`,
      serializeQueryArgs: (args) => `${args.queryArgs.gameId}-${args.queryArgs.marketKey}-${args.queryArgs.sportKey}-${args.queryArgs.includePrices}`
    }),

    getGamesByTeamId: builder.query<GameData[], {teamId: string, marketKey: string, sportKey: string, includePrices: boolean}>({
      query: ({teamId, marketKey, sportKey, includePrices}) => `games?includeBetData=true&includePrices=${includePrices}&sportKey=${sportKey}&teamId=${teamId}&marketKey=${marketKey}`,
      serializeQueryArgs: (args) => `${args.queryArgs.teamId}-${args.queryArgs.marketKey}-${args.queryArgs.sportKey}-${args.queryArgs.includePrices}`
    }),

    getRawGameData: builder.query<DataExplorerGameData, {gameId: string, marketKey: string, sportKey: string}>({
      query: ({gameId, marketKey, sportKey}) => `games?includeBetData=true&sportKey=${sportKey}&gameId=${gameId}&raw=true&marketKey=${marketKey}`,
    })

    // TODO: Make a query that gets by parameter so we don't load all team data every time
  })
})

export const {
  useGetGamesOnlyQuery,
  useGetAllGamesQuery,
  useGetGameByIdQuery,
  useGetGamesByTeamIdQuery,
  useGetRawGameDataQuery
} = gameApi 